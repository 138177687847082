import { Container } from "@chakra-ui/react";

import React from "react";

export default function PageContentContainer({ children, ...props }) {
  return (
    <Container
      sx={{
        "& h2, & h3": {
          marginTop: "0.5rem",
          position: "relative",
          marginBottom: "0.5rem",
          fontSize: "2rem",
          color: "#3182ce",
        },
        "p + h2, ul + h2, div + h2, img + h2": {
          marginTop: "2.5rem",
        },
        "& p, & li, & img": {
          margin: "0.5rem 0",
          fontSize: "1.15rem",
        },
        "& p": {
          textAlign: "justify",
        },
        "& h2:after": {
          content: '""',
          display: "block",
          marginTop: "0.25rem",
          width: "5rem",
          height: "0.25rem",
          backgroundColor: "#2b2f69",
        },
      }}
      maxW="7xl"
      py={5}
      as="main"
      {...props}
    >
      {children}
    </Container>
  );
}
