import { Elements } from "prismic-reactjs";
import {
  Heading,
  ListItem,
  UnorderedList,
  Text,
  Image,
  Box,
} from "@chakra-ui/react";
import React from "react";

const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.heading1: // Heading 1
      return <Heading as="h1">{children}</Heading>;

    case Elements.heading2: // Heading 2
      console.log(children);
      if (!children[0]) {
        return <Box display={"none"}>{children}</Box>;
      }
      return (
        <Heading
          as="h2"
          id={(children[0]?.at(0) ?? "")
            .toLowerCase()
            .replace(/[&\\#, +()$~%.'":*?<>{}]/g, "-")}
        >
          {children}
        </Heading>
      );

    case Elements.heading3: // Heading 3
      return <Heading as="h3">{children}</Heading>;

    case Elements.heading4: // Heading 4
      return <Heading as="h4">{children}</Heading>;

    case Elements.heading5: // Heading 5
      return <Heading as="h5">{children}</Heading>;

    case Elements.heading6: // Heading 6
      return <Heading as="h6">{children}</Heading>;

    case Elements.paragraph: // Paragraph
      if (Array.isArray(children[0]) && children[0][0].length === 1) {
        children[0][0] = "";
      }
      return <Text>{children}</Text>;

    case Elements.preformatted: // Preformatted
      return <Text as="pre">{children}</Text>;

    case Elements.strong: // Strong
      return <Text as="strong">{children}</Text>;

    case Elements.em: // Emphasis
      return <Text as="em">{children}</Text>;

    case Elements.listItem: // Unordered List Item
      return <ListItem>{children}</ListItem>;

    case Elements.oListItem: // Ordered List Item
      return <ListItem>{children}</ListItem>;

    case Elements.list: // Unordered List
      return <UnorderedList>{children}</UnorderedList>;

    case Elements.oList: // Ordered List
      return <UnorderedList>{children}</UnorderedList>;

    case Elements.image: // Image
      // const linkUrl = element.linkTo
      //   ? element.linkTo.url
      //   : //  || linkResolver(element.linkTo)
      //     null;
      // const linkTarget =
      //   element.linkTo && element.linkTo.target
      //     ? { target: element.linkTo.target }
      //     : {};
      // const linkRel = linkTarget.target ? { rel: "noopener" } : {};
      // const img = React.createElement("img", {
      //   src: element.url,
      //   alt: element.alt || "",
      // });
      const img = <Image src={element.url} alt={element.alt} />;
      // return React.createElement(
      //   "p",
      //   propsWithUniqueKey(
      //     { className: [element.label || "", "block-img"].join(" ") },
      //     key
      //   ),
      //   linkUrl
      //     ? React.createElement(
      //         "a",
      //         Object.assign({ href: linkUrl }, linkTarget, linkRel),
      //         img
      //       )
      //     : img
      // );
      return img;

    // case Elements.embed: // Embed
    //   props = Object.assign(
    //     {
    //       "data-oembed": element.oembed.embed_url,
    //       "data-oembed-type": element.oembed.type,
    //       "data-oembed-provider": element.oembed.provider_name,
    //     },
    //     element.label ? { className: element.label } : {}
    //   );
    //   const embedHtml = React.createElement("div", {
    //     dangerouslySetInnerHTML: { __html: element.oembed.html },
    //   });
    //   return React.createElement(
    //     "div",
    //     propsWithUniqueKey(props, key),
    //     embedHtml
    //   );

    // case Elements.hyperlink: // Image
    //   const targetAttr = element.data.target
    //     ? { target: element.data.target }
    //     : {};
    //   const relAttr = element.data.target ? { rel: "noopener" } : {};
    //   props = Object.assign(
    //     {
    //       href: element.data.url || linkResolver(element.data),
    //     },
    //     targetAttr,
    //     relAttr
    //   );
    //   return React.createElement("a", propsWithUniqueKey(props, key), children);

    // case Elements.label: // Label
    //   props = element.data
    //     ? Object.assign({}, { className: element.data.label })
    //     : {};
    //   return React.createElement(
    //     "span",
    //     propsWithUniqueKey(props, key),
    //     children
    //   );

    // case Elements.span: // Span
    //   if (content) {
    //     return content.split("\n").reduce((acc, p) => {
    //       if (acc.length === 0) {
    //         return [p];
    //       } else {
    //         const brIndex = (acc.length + 1) / 2 - 1;
    //         const br = React.createElement(
    //           "br",
    //           propsWithUniqueKey({}, brIndex)
    //         );
    //         return acc.concat([br, p]);
    //       }
    //     }, []);
    //   } else {
    //     return null;
    //   }

    default:
      // Always include a default that returns null
      return null;
  }
};

export { htmlSerializer };
